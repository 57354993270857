body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Container do curso com texto e imagem */

.name-course-size {
  display: flex;
  justify-content: center;
}

.container-course {
  display: flex;
  justify-content: left;
  align-items: center;
  margin-top: 42px;
  height: 120px;
  width: 1032px;
}

/* Imagem do curso */

.container-course img {
  max-width: 173px;
  height: 90px;
  margin-left: 15px;
}

.img-card-smartphone {
  display: none;
  width: 100%;
}

/* Nome do curso e valor */

.name-value {
  text-align: left;
  margin-left: 35px;
}

.mt-4 {
  margin-top: 16px;
}
.text-sm {
  font-size: 12px;
}
.name-value text {
  font-weight: 500;
  color: #333333;
  font-size: 14px;
}

.name-value h2 {
  font-weight: bold;
  color: #333333;
  font-size: 20px;
  margin-bottom: 15px;
  margin-top: 10px;
}

.name-value span {
  font-weight: normal;
  color: #6d6d6d;
  font-size: 14px;
}

.input-who-pay {
  margin-right: 50px;
}

.text-color-grey {
  color: #6d6d6d;
  font-size: 14px;
}

a:-webkit-any-link {
  color: #6d6d6d;
  text-decoration: none;
}

/* Linha divisor */

hr {
  margin-bottom: 23px;
  height: 1px;
  background-color: #f0f0f0;
  border: none;
}

/* Div quem fará o pagamento */

.who-pay {
  margin-bottom: 20px;
}

/* Título quem fará o pagamento */

.who-pay .who-pay-title {
  font-weight: 500;
  font-size: 16px;
  color: #333333;
}

/* Campos de preenchimento dos dados */

.container-input {
  width: 100%;
  height: 100%;
  display: block;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.width-input {
  width: 300px;
}

.more-component-input {
  display: flex;
}

.input-style {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}

.search-text {
  margin-left: 15px;
  cursor: pointer;
}

.data-input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-right: 15px;
}

.data-input:last-child {
  margin-right: 0px;
}

/* Título do input */

.title-iput {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #333333;
  margin-bottom: 8px;
}

/* Asteristico */
.asterisk {
  color: #333333;
}

/* Formulário Input */
.form-input {
  background: white;
  border: 1px solid #d5d5d5;
  box-sizing: border-box;
  border-radius: 15px;
  height: 42px;
  width: 100%;
  color: #333333;
}

input[type='text'i] {
  padding: 1px 10px;
}

/* Check Politica e Privacidade */

.check-privacy {
  margin-top: 25px;
  display: flex;
}

/* Botão */

.button {
  display: flex;
}

.button-next {
  width: 170px;
  height: 50px;
  border-radius: 4px;
  background: #003bde;
  border: none;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  cursor: pointer;
}

.button-next:hover {
  background: #0434ba;
}

/* Botão Voltar */

.button-return {
  width: 172px;
  height: 50px;
  border-radius: 4px;
  border: none;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #333333;
  cursor: pointer;
  background: #dadada;
  border: none;
  margin-right: 15px;
}

.button-return:hover {
  background: #b4b4b4;
}

/* Botão Finalizar */

.btn-finish {
  width: 172px;
  height: 50px;
  border-radius: 4px;
  border: none;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  cursor: pointer;
  background: #28d071;
  border: none;
}

.btn-finish:hover {
  background: #23b261;
}

.back-my-courses {
  background: transparent;
  border: none;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  color: #0434ba;
  cursor: pointer;
}

/* Dados da empresa */

.info-company {
  display: flex;
  margin-top: 25px;
  color: #333333;
}

.hr-data {
  margin-top: 20px;
  margin-bottom: 20px;
  height: 1px;
  background-color: #f0f0f0;
  border: none;
}

/* Tela pagamento */

/* Titulo da tela */

.title-user-pay {
  margin-right: 15px;
  font-weight: normal;
  font-size: 16px;
  margin-bottom: 40px;
  color: #333333;
  display: grid;
  justify-items: start;
}
.title-user-pay-transfere {
  margin-right: 15px;
  font-weight: normal;
  font-size: 16px;
  margin-bottom: 20px;
  color: #333333;
  display: grid;
  justify-items: start;
}
.payment-name-greet {
  color: #41a4f4;
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 8px;
}
.payment-info {
  color: #41a4f4;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 8px;
}
.opacity {
  opacity: 0.5;
}

/* Botões com formas de pagamento */

.container-payment {
  width: 100%;
}

.buttons-payments {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

/* Botão de forma de pagamento disponível */

.button-payment-card {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 30px;
}

.button-working,
.coupon-card {
  background: #ffffff;
  border: none;
  box-shadow: 0px 5px 15px rgba(67, 69, 75, 0.1);
  font-size: 14px;
  font-weight: bold;
  color: #333333;
  width: 160px;
  height: 160px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.separator {
  width: 30px;
}

.button-working img {
  margin-bottom: 15px;
}

.button-working span {
  font-family: 'Montserrat', sans-serif;
  font-weight: normal;
  font-size: 12px;
  color: #6d6d6d;
}

.diferent-color {
  color: #c4c4c4;
}

/*  Card  */

.card {
  background: #ffffff;
  box-shadow: 0px 5px 15px rgba(67, 69, 75, 0.1);
  border-radius: 8px;
}

/* Tíulo tranferência */

.title-trasnfer-card {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
}

/* Subtítulo tranferência */

.subtitle-trasnfer-card {
  display: flex;
  justify-content: flex-start;
  margin-top: 25px;
  font-size: 14px;
  color: #6d6d6d;
  font-weight: 400;
}

/* Dados da conta para tranferência */

.data-bank-transfer {
  display: flex;
  flex-direction: column;
  line-height: 30px;
  align-items: flex-start;
  margin-top: 20px;
  color: #6d6d6d;
}
.data-bank-transfer-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 20px;
}
.data-bank-transfer-element {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: #6d6d6d;
  margin-bottom: 20px;
}
.data-bank-transfer-element-row {
  display: flex;
  gap: 40px;
  justify-content: space-between;
  color: #6d6d6d;
  margin-bottom: 14px;
}

/* Botão Finalizar */

.btn-finish {
  display: block;
  margin: 0 auto;
}

/* Tela sucesso */

.success-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: calc(100vh - 395px);
}

.img-success {
  display: block;
  margin: 0 auto;
  margin-bottom: 65px;
}

/* Stepper Aluno */

.stepper-conteiner {
  max-width: 700px;
  height: 35px;
  display: block;
  margin: 0 auto;
  background: #41a4f4;
  border-radius: 8px;
  color: white;
  padding-left: 22px;
  margin-top: 25px;
  margin-bottom: 25px;
}

/* Arrow Stepper */

.icon-arrow {
  height: 35px;
  width: 24px;
  display: flex;
  align-items: center;
}

/* Dados do stepper */

.stepper-data {
  display: flex;
  justify-content: space-around;
}

.stepper-data button {
  width: 100%;
  height: 35px;
  background-color: transparent;
  border: none;
  color: white;
  font-family: 'Montserrat', sans-serif;
  font-size: 13.5px;
}

.stepper-data button.active {
  font-weight: bold;
}

/* Card com dados */

.data-card {
  width: 1032px;
  height: 680px;
  display: flex;
  margin: 0 auto;
  margin-bottom: 45px;
}

.card-img-data {
  display: flex;
}

.field-data {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 23px;
  position: relative;
}

.field-data-container {
  flex: 1;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

/* Footer igual o do site */

.omd-footer {
  background-color: white;
  height: 280px;
  display: flex;
  flex-direction: column;
  line-height: 29px;
  justify-content: center;
}

.link-diferent {
  text-decoration-line: underline;
  color: #000;
}

.link-diferent a {
  color: #333333;
}

.omd-title-footer {
  font-weight: bold;
}

.card-container {
  padding: 10px;
}
.asaas-log {
  margin-left: auto;
}
.protected {
  display: flex;
  align-items: center;
  gap: 4px;
}
.safe-payment {
  margin-bottom: 15px;
  width: 604px;
  height: 40px;
  border-radius: 4px;
  background-color: #012db9;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #fefefe;
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  justify-content: space-between;
}
/* celular */

@media screen and (max-width: 480px) {
  /* Input */

  .more-component-input {
    display: flex;
    flex-direction: column;
  }

  .container-input {
    margin-top: 20px;
  }

  .data-input {
    margin-right: 0px;
    width: 96%;
  }

  /* Container do curso */

  .container-course {
    height: auto;
    display: flex;
    flex-direction: column;
    padding: 15px;
    margin-left: 10px;
    margin-right: 10px;
  }

  /* Nome do curso */

  .name-value {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 0px;
  }

  .name-value h2 {
    text-align: center;
  }

  /* Imagem do curso */

  .container-course img {
    margin-left: 0px;
    margin-bottom: 10px;
  }

  /* Imagem do curso */

  .img-card {
    display: none;
  }

  .img-card-smartphone {
    display: block;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  /* Tamanho do input */

  .data-card {
    width: 100% !important;
    height: 100% !important;
    flex-direction: column;
  }

  /* Tamanho do input */

  .field-data {
    height: 100%;
    padding: 2%;
  }

  /* Botão voltar */

  .button-return {
    margin-right: 0px;
    margin-bottom: 15px;
  }

  /* Botão próximo */

  .button-next {
    margin-bottom: 15px;
  }

  /* Conteiner dos dados do stepper */

  .stepper-conteiner {
    display: none;
  }

  .stepper-data {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
  }

  /* Politica de privacidade */

  .check-privacy {
    margin-right: 15px;
  }

  /* Titulo Quem vai pagar? */

  .who-pay {
    margin-top: 15px;
  }

  /* Linha */

  hr {
    margin-right: 15px;
  }

  /* Footer card */

  .footer-card {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }

  .button-payment-card {
    margin-right: 15px;
    margin-bottom: 10px;
  }

  .button-working {
    max-width: 138px;
  }
}
.btn-finish {
  margin-bottom: 15px;
}

.back-my-courses {
  margin-bottom: 15px;
}

.width-input {
  margin-top: 20px;
}

.button-payment .button-working {
  cursor: pointer !important;
}

.invalid-feedback {
  font-size: 12px;
  color: rgb(251, 61, 61);
  margin-top: -10px;
  margin-bottom: 10px;
}
.error-feedback {
  font-size: 12px;
  color: rgb(251, 61, 61);
}
.grid-container {
  font-size: 12px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: 600px;
  margin: 0 auto;
}
.grid-item {
  display: grid;
  justify-content: start;
  margin-right: 20px;
}
/* Optional: Make the first column bold */
.grid-item:nth-child(odd) {
  font-weight: bold;
}
.center-div {
  display: grid;
  justify-items: center;
}
.coupon-card {
  width: 450px;
  max-height: 40px;
  margin-top: -50px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #41a4f4;
  color: #ffffff;
}

.coupon-code {
  max-width: 300px;
  height: 25px;
  border-radius: 4px;
  outline: 0;
  border: solid 1px #acacac;
}
.card-forum-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
}
.card-input {
  width: 200px;
  height: 25px;
  border-radius: 4px;
  outline: 0;
  border: solid 1px #acacac;
}
.coupon-text {
  display: flex;
  align-items: center;
  max-width: 150px;
}

.btn-coupon {
  border: none;
  background-color: #fff;
  cursor: pointer;
  border-radius: 2px;
  font-size: 12px;
  height: 29px;
}

.qr-code-container {
  margin: 0 auto;
}

.qr-code {
  width: 200px;
  margin: 0 auto;
}

.button-finish-container {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 15px;
}

.button-finish-container > button {
  height: 50px;
  width: 180px;
  border-radius: 4px;
  border: none;
  font-size: 16px;
  cursor: pointer;
}

.button-finish-container :first-child {
  background-color: #acacac;
}

.button-finish-container :last-child {
  background-color: #28d071;
  color: #fff;
}

.boleto-button {
  background-color: #acacac;
  height: 50px;
  width: 180px;
  border-radius: 4px;
  border: none;
  font-size: 16px;
  cursor: pointer;
}
.feedback-coupon {
  position: absolute;
  margin-bottom: 120px;
  border-radius: 3px;
  box-shadow: -10px 5px 15px #333333;
  margin-left: 130px;
  background-color: #23b261;
  padding: 12px;
  animation: couponAppear 1s ease-in-out;
}

@keyframes couponAppear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes couponDissappear {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.step-title {
  font-size: 20px;
  margin-top: -60px;
  margin-bottom: 100px;
  display: flex;
  border-bottom: 1px solid rgba(87, 87, 87, 0.452);
  padding-bottom: 15px;
}
